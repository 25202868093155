<template>
  <div class="menubox">
    <div :class="isTrue ? '' : menu2" collapse-transition="true" class="menu" v-if="$route.meta.show != false">
      <div class="header">
        <div class="header-title">
          <div class="seat" @click="goRefash()">
            <div class="img-box">
              <img v-if="$judgeServerHostname() == 1" src="../assets/loginInnerMongolia/logo.png" alt=""
                style="width: 25px; height: 25px" />
              <img v-else src="../assets/logo-w.png" />
            </div>
            <h4 v-show="showtitle == false">
              <!-- 内蒙 -->
              <template v-if="$judgeServerHostname() == 1">
                <span style="font-size: 14px; text-align: center; margin-top: 10px">内蒙古自治区工伤预防<br />线上培训平台</span>
              </template>
              <!-- 人力资源 -->
              <template v-else-if="$judgeServerHostname() == 3">
                <span style="font-size: 14px; text-align: center; margin-top: 10px">人力资源平台</span>
              </template>
              <template v-else>
                <span v-if="userJson.roleId == '1' || userJson.roleId == '-1'">运营管理端</span>
                <span v-else-if="userJson.roleId == '2'">政府监管端</span>
                <span v-else-if="userJson.roleId == '3'">机构管理端</span>
                <span v-else-if="userJson.roleId == '-3'">普惠机构管理端</span>
                <span v-else-if="userJson.roleId == '-4'">山西监管端</span>
                <span v-else-if="userJson.roleId == '-5'">机构代理商</span>
                <span v-else-if="userJson.roleId == '-6'">企业管理端</span>
                <span v-else-if="userJson.roleId == '-7'">机构管理端</span>
                <span v-else-if="userJson.roleId == '-10'">律师服务</span>
                <span v-else>课程方管理端</span>
              </template>
            </h4>
          </div>
        </div>
        <div class="nav-title">
          <div class="seat" @click="changeCollapse()">
            <div class="img-box" style="margin: inherit">
              <img src="../assets/menu.png" />
            </div>
            <span />
          </div>
        </div>
      </div>
      <div class="nav">
        <div class="nav-box">
          <el-menu :collapse="isTrue" :default-active="$route.path" :default-openeds="menuOpeneds"
            background-color="#324767" text-color="#fff" class="menu-control" @select="menuSelect" @open="handleOpen"
            @close="handleClose">
            <template v-for="(item, index) in getMenu">
              <el-submenu v-if="item.children && item.children.length" :index="'' + (index + 1)" :key="index">
                <template slot="title">
                  <img :src="item.icon ? require('@/assets/' + item.icon + '.png') : ''
                    " style="width: 30px" />
                  <span slot="title">{{ item.name || "" }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-for="(item1, index1) in item.children" :index="item1.url" :key="index1"
                    :v-show="item1.url == null ? !show : show" @click="doRouter(item1.url)">
                    <span>{{ item1.name || "" }}</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item v-else :index="'' + (index + 1)" :key="'menu-item'+index" style="padding-left: 0"
                @click="doRouter(item.url)">
                <img :src="item.icon ? require('@/assets/' + item.icon + '.png') : ''
                  " style="padding-left: 0.75rem; width: 30px" />
                <span style="padding-left: 12px; color: #fff">{{
                  item.name || ""
                  }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="control">
      <template v-if="$judgeServerHostname() && $judgeServerHostname() == 3">
        <TopbarHR />
      </template>
      <template v-else>
        <div class="header" v-if="$route.meta.show != false">
          <div class="topAdvertisement" v-if="topAdvertisement">
            <img src="../assets/topAdvertisementIcon.png" alt="" />
            专业人力资源服务,助力企业用工招聘。<span @click="topAdvertisementInfo">点击进行用工登记<i class="el-icon-arrow-right"></i></span>
          </div>
          <div class="topAdvertisement" v-else></div>
          <div class="headerBtn">
            <div class="seat">
              <div class="headr-btn-user">
                <div class="headr-btn-title" @click="conversionShow = true">
                  <img src="@/assets/huansuan.png" alt style="width: 12px; height: 12px;margin-top: 2px;" />
                  <span style="padding-left: 5px">课时换算</span>
                </div>
              </div>
              <div class="headr-btn-user">
                <el-popover placement="bottom" width="100%" trigger="click" v-model="wxInfoShow">
                  <div class="newInfoc1" v-if="userJson.roleId == '-10'">
                    请扫描二维码关注安知公众号，以便及时接收用户线上咨询服务提醒
                  </div>
                  <div class="newInfoc1" v-else>
                    微信扫一扫,绑定账号可以在公众号上申请协议啦
                  </div>
                  <div style="display: flex; justify-content: center">
                    <img :src="QRCodeSrc" alt="" width="150px" height="150px" />
                  </div>
                  <el-table :data="QRCodeBindList" size="small">
                    <el-table-column width="180" label="当前绑定的微信帐号">
                      <template slot-scope="scope">
                        {{ scope.row.openid }}
                      </template>
                    </el-table-column>
                    <el-table-column align="right">
                      <template slot-scope="scope">
                        <el-button round size="small" @click="handleUnbind(scope.row.relationId)">解除绑定</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="headr-btn-user" style="cursor: pointer" slot="reference">
                    <div class="headr-btn-title">
                      <span style="
                        vertical-align: middle;
                        font-size: 0.875rem;
                        color: #8c8b8b;
                      ">{{ userJson.roleId == '-10'?'服务提醒':'微信公众号' }}</span>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div class="headr-btn-user">
                <el-popover v-if="showChannel" placement="bottom" width="100%" trigger="click" @show="getChannelQRCode"
                  v-model="channelCodeShow">
                  <div style="display: flex; justify-content: center">
                    <img :src="ChannelQRCodeSrc" alt="" width="150px" height="150px" />
                  </div>
                  <div style="text-align: center" class="newInfoc1">
                    <el-button type="primary" @click="downloadChannelQRCode" size="small">下载二维码</el-button>
                  </div>
                  <div class="headr-btn-user" style="cursor: pointer" slot="reference">
                    <div class="headr-btn-title">
                      <span style="
                        vertical-align: middle;
                        font-size: 0.875rem;
                        color: #8c8b8b;
                      ">招生二维码</span>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div class="headr-btn-user">
                <el-popover placement="bottom" width="100%" trigger="click" v-model="newsInfoShow" @show="getNewsInfo">
                  <div class="newInfoc1" @click="jumpNewsList" style="
                  position: absolute;
                  top: 18px;
                  right: 20px;
                  z-index: 2;
                  font-size: 12px;
                  color: #5c6be8;
                  cursor: pointer;
                ">
                    消息中心
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <el-table :data="newsInfo" size="small">
                    <el-table-column width="300" label="站内消息通知">
                      <template slot-scope="scope">
                        <div @click="toNewsInfo(scope.row)" style="cursor: pointer">
                          <p>{{ scope.row.msgName }}</p>
                          <el-tooltip class="item" effect="dark" :content="scope.row.msgContent" placement="top">
                            <p style="
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          ">
                              {{ scope.row.msgContent }}
                            </p>
                          </el-tooltip>

                          <p style="color: #c0c4cc">{{ scope.row.createTime }}</p>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div v-show="newsInfoNumber > 5" class="newInfoc2" @click="jumpNewsList" style="
                  text-align: center;
                  line-height: 39px;
                  color: #5c6be8;
                  cursor: pointer;
                  font-size: 12px;
                ">
                    查看更多
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div class="headr-btn-user" style="cursor: pointer" slot="reference">
                    <div class="headr-btn-title">
                      <el-badge :value="!newsInfoNumber || newsInfoNumber < 1
                          ? ''
                          : newsInfoNumber
                        " :max="99" class="item">
                        <img src="@/assets/xxts.png" alt style="
                                        width: 12px;
                                        height: 12px;
                                        position: relative;
                                        top: 3px
                      " />
                        <span style="
                                          padding-left: 5px;
                                          vertical-align: middle;
                                          font-size: 0.875rem;
                                          color: #8c8b8b;
                                        ">消息</span>
                      </el-badge>
                    </div>
                  </div>
                </el-popover>
              </div>

              <div class="headr-btn-user">
                <div class="headr-btn-title">
                  <img src="@/assets/user.png" alt style="width: 12px; height: 12px;margin-top: 2px;" />
                  <span style="padding-left: 5px">{{
                    userJson.fullname || "管理员"
                    }}</span>
                </div>
              </div>
              <div class="headr-btn-user">
                <div class="headr-btn-title" @click="passwordShow = true">
                  <img src="@/assets/pass.png" alt style="width: 12px; height: 12px;margin-top: 2px;" />
                  <span style="padding-left: 5px">修改密码</span>
                </div>
              </div>

              <div class="headr-btn-out">
                <div class="headr-btn-title" @click="loginOut">
                  <img src="@/assets/exit.png" alt style="width: 12px; height: 12px;margin-top: 2px;" />
                  <span style="padding-left: 5px">退出</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="Informationbox" v-if="$route.meta.show === undefined || $route.meta.show === true">
        <keep-alive>
          <router-view v-if="$route.meta.state" />
        </keep-alive>
        <router-view v-if="!$route.meta.state" />
      </div>
      <div class="Informationboxs" v-else>
        <router-view />
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog title="修改密码" :before-close="passwordclose" :visible="passwordShow" width="600px" center
      :close-on-press-escape="false" :close-on-click-modal="false" :show-close="!userJson.passwordExpire">
      <!-- resetPassword -->
      <div>
        <div v-show="userJson.passwordExpire" style="padding-bottom: 10px">
          <p style="color: #e90101">
            温馨提示：依据网络安全等级要求，请及时修改初始密码，确保个人账户信息安全。
          </p>
        </div>
        <el-form ref="passform" :model="ruleForm" :rules="rules" class="elForm" label-position="left">
          <el-form-item label="原密码" prop="oldPassword" class="menuLabel">
            <el-input type="password" v-model="ruleForm.oldPassword" clearable placeholder="请输入原密码" />
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword" class="menuLabel">
            <el-input type="password" v-model="ruleForm.newPassword" clearable
              placeholder="请输入8-20位且包含小写字母、大写字母、数字、特殊符号中的三种组合" />
          </el-form-item>
          <el-form-item label="确认新密码" prop="newPasswordOk" class="menuLabel">
            <el-input type="password" v-model="ruleForm.newPasswordOk" clearable placeholder="请再次输入新密码" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button class="bgc-bv" @click="resetPassword" style="width: 100%">修改密码</el-button>
      </span>
    </el-dialog>
    <!-- 课时换算弹窗 -->
    <el-dialog title="课时换算" :before-close="conversionclose" :visible="conversionShow" width="364px" class="conversion"
      center :close-on-press-escape="false" :close-on-click-modal="false">
      <div>
        <el-form ref="conversionform" :model="conversionform" label-position="left" label-width="68px">
          <el-form-item label="视频时长">
            <div class="conversionItem type-1">
              <div class="conversionInner">
                <el-input-number type="text" v-model="conversionform.cHour" clearable size="mini" :min="0" :step="1"
                  :precision="0" :controls="false" />
                <span>时</span>
              </div>
              <div class="conversionInner">
                <el-input-number type="text" v-model="conversionform.cMinute" clearable size="mini" :min="0" :max="59"
                  :step="1" :precision="0" :controls="false" />
                <span>分</span>
              </div>
              <div class="conversionInner">
                <el-input-number type="text" v-model="conversionform.cSecond" clearable size="mini" :min="0" :max="59"
                  :step="1" :precision="0" :controls="false" />
                <span>秒</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="每课时长" prop="">
            <div class="conversionItem type-2">
              <div class="conversionInner">
                <el-input-number type="text" v-model="conversionform.cPerMinute" clearable size="mini" :min="1"
                  :step="1" :precision="0" :controls="false" />
                <span>分</span>
              </div>
            </div>

          </el-form-item>
          <el-form-item label="保留小数" prop="">
            <div class="conversionItem type-3">
              <div class="conversionInner">
                <el-select size="mini" v-model="conversionform.cRetainDecimals" @change="changeRetainDecimals">
                  <el-option key="10" label="不进位" value="10"></el-option>
                  <el-option key="20" label="四舍五入" value="20"></el-option>
                </el-select>

                <!--                            1.567 2 1.57 1.56-->
              </div>
              <div class="conversionInner">
                <el-select size="mini" v-model="conversionform.cDigit" :disabled="conversionform.cRetainDecimals == ''">
                  <el-option key="0" label="0" value="0"></el-option>
                  <el-option key="1" label="1" value="1"></el-option>
                  <el-option key="2" label="2" value="2"></el-option>
                  <el-option key="3" label="3" value="3"></el-option>
                  <el-option key="4" label="4" value="4"></el-option>
                </el-select>
                <span>位</span>
              </div>
            </div>

          </el-form-item>
          <el-form-item label="计算结果" prop="">
            <span class="resultBg"><span @click="handleCopy"
                v-if="conversionform.cClassHour || conversionform.cClassHour == '0'">{{ conversionform.cClassHour
                }}</span>课时</span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button round size="small" class="bgc-bv" @click="clickConversion" style="width: 204px;">换算</el-button>
      </span>
    </el-dialog>
    <!-- 图片 弹窗 -->
    <el-dialog :visible="picBigerImgUrl ? true : false" size="tiny" :before-close="picBigerClose">
      <img width="100%" :src="picBigerImgUrl" alt />
    </el-dialog>
    <!-- 视频 弹窗 -->
    <el-dialog v-if="videoUrl.guangzhou != 'guangzhou'" :title="'课件名称：' + videoUrl.kpointName" :visible="videoUrl.kpointVideoId || videoUrl.kpointVideoUrl ? true : false
      " size="tiny" width="1200px" top="3%" @close="videoUrlClose">
      <div v-show="videoUrl.kpointId">
        <h3>基本信息</h3>
        <div class="videoMation">
          <span>
            <span>课件学时:</span>
            <div>{{ ruleForm.kpointLessonNum }}</div>
            学时
          </span>
          <span>
            <span>课件来源:</span>
            <div>{{ ruleForm.compOwnerName }}</div>
          </span>
          <span>
            <span>培训类型:</span>
            <div>{{ ruleForm.trainTypeNamePath }}</div>
          </span>
          <span v-show="ruleForm.postName">
            <span>岗位类型:</span>
            <div>{{ ruleForm.postName }}</div>
          </span>
          <span v-show="ruleForm.industryNamePath">
            <span>行业类型:</span>
            <div>{{ ruleForm.industryNamePath }}</div>
          </span>
          <span v-show="ruleForm.occupationNamePath">
            <span>职业/工种:</span>
            <div>{{ ruleForm.occupationNamePath }}</div>
          </span>
          <span v-show="ruleForm.trainLevelName">
            <span>培训等级:</span>
            <div>{{ ruleForm.trainLevelName }}</div>
          </span>
          <span v-show="ruleForm.duration">
            <span>视频时长:</span>
            <div style="color: #409eff">{{ videoDuration }}</div>
          </span>
          <span style="display: flex">
            <span>课件封面:</span>
            <div class="img-el-upload" style="width: 50px" v-if="ruleForm.thumbnail">
              <el-image :src="ruleForm.thumbnail || require('@/assets/develop.png')" fit="contain"
                :preview-src-list="List"></el-image>
            </div>
            <div v-else>无</div>
          </span>
        </div>
      </div>
      <div v-show="videoUrl.noBodyLearning">
        <div class="videoMation">
          <span v-if="!videoUrl.details || videoUrl.details.length === 0">上传课件已被删除，仅存复制资源</span>
          <el-table v-if="videoUrl.details && videoUrl.details.length > 0" ref="multipleTable" :data="videoUrl.details"
            size="small" tooltip-effect="dark" style="width: 100%" stripe>
            <el-table-column label="课件名称" align="left" show-overflow-tooltip prop="kpointName" min-width="100" />
            <el-table-column label="机构名称" prop="compName" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip min-width="100"></el-table-column>
            <el-table-column label="创建人" prop="fullname" show-overflow-tooltip min-width="100"></el-table-column>
            <el-table-column label="培训类型" prop="trainTypeNamePath" show-overflow-tooltip
              min-width="100"></el-table-column>
            <el-table-column label="岗位类型" prop="postName" show-overflow-tooltip min-width="100"></el-table-column>
            <el-table-column label="行业类型" prop="industryNamePath" show-overflow-tooltip
              min-width="100"></el-table-column>
            <el-table-column label="职业/工种" prop="occupationNamePath" show-overflow-tooltip
              min-width="100"></el-table-column>
            <el-table-column label="培训等级" prop="trainLevelName" show-overflow-tooltip min-width="100"></el-table-column>
          </el-table>
        </div>
      </div>
      <player ref="player1" v-if="(videoUrl.kpointVideoId || videoUrl.kpointVideoUrl) && showVideo"
        :videoId="videoUrl.kpointVideoId" :kpointId="videoUrl.kpointId" :courseId="videoUrl.courseId"
        :kpointSource="ruleForm.kpointSource" :kpointVideoUrl="videoUrl.kpointVideoUrl" />
    </el-dialog>
    <!-- 广州 -->
    <el-dialog v-else :title="videoUrl.kpointName + '视频录像'" :visible="videoUrl.kpointVideoId ? true : false" size="tiny"
      width="1200px" top="3%" @close="videoUrlClose1">
      <player ref="player2" v-if="videoUrl.kpointVideoId" :videoId="videoUrl.kpointVideoId"
        :kpointId="videoUrl.kpointId" :kpointSource="ruleForm.kpointSource" />
    </el-dialog>
    <download-notification :visible="downloadItems.length > 0" :duration="0"
      position="bottom-right"></download-notification>
    <!-- 临期提醒弹窗 -->
    <el-dialog title="临期提醒" @close="onTimeReminderclose" :visible="onTimeReminder" width="80%" center
      :close-on-press-escape="false" :close-on-click-modal="false" :show-close="!userJson.passwordExpire">
      <!-- resetPassword -->
      <div style="height: 400px">
        <span class="cur-a" style="
            margin-left: 10px;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
          " @click="lookMore">更多</span>
        <div class="ovy-a">
          <el-table ref="multipleTable" :data="tableData" :height="400" size="small" tooltip-effect="dark"
            style="width: 100%" :header-cell-style="tableHeader" @selection-change="handleSelectionChange" stripe>
            <el-table-column label="状态" align="left" show-overflow-tooltip prop="msgState" min-width="50">
              <template slot-scope="scope">
                <span v-if="scope.row.msgState == '30'">
                  <i class="bjInfo1"></i>已读
                </span>
                <span v-else-if="scope.row.msgState == '10'">
                  <i class="bjInfo2"></i>未读
                </span>
                <span v-else> <i class="bjInfo3"></i>未知 </span>
              </template>
            </el-table-column>
            <el-table-column label="标题" prop="msgName" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="内容" prop="msgContent" show-overflow-tooltip min-width="250"></el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button class="bgc-bv" @click="batchDeleteRead">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import player from "@/components/player.vue";
import DownloadNotification from "@/components/DownloadNotification";
import axios from "axios";
// import axios2 from "axios2";
import store from "../store/index";
import { createSwigger, distorySwigger } from "../utils/swigger";
import TopbarHR from "@/components/HumanResources/Topbar.vue";

export default {
  name: "views_Menu",
  components: {
    player,
    DownloadNotification,
    TopbarHR,
  },
  data() {
    let validatepass = (rule, value, callback) => {
      if (
        this.ruleForm.newPassword &&
        value.trim() != this.ruleForm.newPassword.trim()
      ) {
        callback(new Error("请检查两次输入的新密码是否相同"));
      } else {
        callback();
      }
    };
    let validPassword = (rule, value, callback) => {
      if (value == this.ruleForm.oldPassword) {
        callback(new Error("新密码与原密码相同！"));
      } else {
        let result = this.checkPassword(value);
        if (!result.flag) {
          callback(new Error(result.msg));
        } else {
          callback();
        }
      }
    };
    return {
      showVideo: false,
      getMenu: [], // 菜单
      List: [], // 菜单
      // 内参
      menu2: "menu-cur",
      isTrue: false,
      showtitle: false,
      passworkchange: true,
      imgdialog: false,
      menuActive: "1",
      menuOpeneds: ["1"],





      passwordShow: false, // 修改密码
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        newPasswordOk: "",
      },
      newsInfoShow: false,
      // 消息-列表数据
      newsInfo: [],
      // 消息-列表数据-长度
      newsInfoNumber: "",
      getInfosSetInterval: null,
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validPassword, trigger: "blur" },
        ],
        newPasswordOk: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { validator: validatepass, trigger: "blur" },
        ],
      },
      onTimeReminder: false,
      tableData: [],
      wxInfoShow: false,
      QRCodeSrc: "", //微信二维码路径
      QRCodeBindList: [], //绑定列表
      topAdvertisement: false, // 顶部广告
      // 是否显示渠道二维码
      showChannel: false,
      channelId: null,
      channelCodeShow: false,
      ChannelQRCodeSrc: "",
      ChannelQRCodeDownloadSrc: "",

      /* 课时换算 */
      conversionShow: false, // 课时换算
      conversionform: {
        cHour: '0',
        cMinute: '0',
        cSecond: '0',
        cPerMinute: '45',
        cRetainDecimals: '10',
        cDigit: '2',
        cClassHour: '',
      },
      // conversionrules: {
      //     cHour: [
      //         { required: true, message: "请输入视频时长小时数", trigger: "blur" },
      //     ],
      //     cMinute: [
      //         { required: true, message: "请输入视频时长分钟数", trigger: "blur" },
      //     ],
      //     cSecond: [
      //         { required: true, message: "请输入视频时长秒数", trigger: "blur" },
      //     ],
      //     cPerMinute: [
      //         { required: true, message: "请输入每课时长分数", trigger: "blur" },
      //     ],
      // },
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
      hasDictionary: "getDictionary",
      picBigerImgUrl: "getPicBigUrl",
      videoUrl: "getVideoUrl",
      downloadItems: "getDownloadItems",
      menu: "getMenu",
    }),
    videoDuration() {
      let str = "";
      let duration = this.ruleForm.duration;
      if (duration) {
        if (parseInt(duration / 60) > 0) {
          str = parseInt(duration / 60) + "分";
        }
        str += (duration % 60) + "秒";
      }
      return str;
    },
  },
  watch: {


    videoUrl: function (o, n) {
      if (o.kpointId != undefined) {
        this.showVideo = false
        this.getDetail(o.kpointId);
      }
    },
    // 监听 - websocketData
    // "$store.state.websocketData": {
    //   handler(newName, oldName) {
    //     if (newName && newName != "连接成功！") {
    //       this.newsInfoNumber = JSON.parse(newName).data.unreadMsgCount;
    //     }
    //   },
    //   // 立即先去执行里面的handler方法 - 类似初始化就执行
    //   immediate: true,
    //   // 开启深度监听
    //   deep: true
    // }
  },
  created: function () {
    // 获取字典
    this.setDictionary();
    this.getMenuData();
    if (this.userJson.passwordExpire) {
      this.passwordShow = true;
      this.passworkchange = true;
    }
    this.getData();
    this.getQRCode();
    this.getBindQRCodeList();
    this.checkHaveChannelQrCode();
    this.getInfos();
    this.getInfosSetInterval = setInterval(() => {
      this.getInfos();
    }, 600000);
    // this.getMechanismEnterpriseName();
  },
  mounted() { },
  beforeDestroy() {
    clearInterval(this.getInfosSetInterval); // 清除定时器
    this.getInfosSetInterval = null;
  },
  methods: {
    /*课时换算*/
    // 保留小数方式改变
    changeRetainDecimals(e) {
      console.log(e)
      // this.conversionform.cDigit = '2'
    },
    // 关闭课时换算 - 对话框
    conversionclose() {
      this.conversionform = {
        cHour: '0',
        cMinute: '0',
        cSecond: '0',
        cPerMinute: '45',
        cRetainDecimals: '10',
        cDigit: '2',
        cClassHour: ''
      }
      this.conversionShow = false;
    },
    //点击复制
    handleCopy() {
      const oInput = document.createElement('input')
      oInput.value = this.conversionform.cClassHour
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      oInput.remove()
    },
    // 点击换算
    clickConversion() {
      if (!this.conversionform.cHour && this.conversionform.cHour != 0) {
        this.$message.warning('请输入视频时长小时数！')
        return
      }
      if (!this.conversionform.cMinute && this.conversionform.cMinute != 0) {
        this.$message.warning('请输入视频时长分钟数！')
        return
      }
      if (!this.conversionform.cSecond && this.conversionform.cSecond != 0) {
        this.$message.warning('请输入视频时长秒数！')
        return
      }
      if (!this.conversionform.cPerMinute && this.conversionform.cPerMinute != 0) {
        this.$message.warning('请输入每课时长分钟数！')
        return
      }
      // 视频时长（秒）
      let coursewareDuration = this.conversionform.cHour * 3600 + this.conversionform.cMinute * 60 + this.conversionform.cSecond
      // 每课时长（秒）
      let eachClassDuration = this.conversionform.cPerMinute * 60
      // 结果课时数 = 视频时长（秒）/每课时长（秒）
      let resultClassHour = coursewareDuration / eachClassDuration
      console.log(resultClassHour)
      if (resultClassHour % 1 === 0) {
        // resultClassHour是整数
        this.conversionform.cClassHour = '' + resultClassHour
      } else {
        // resultClassHour不是整数
        if (this.conversionform.cRetainDecimals == '10') {
          //不进位
          if (resultClassHour > 1 && this.conversionform.cDigit == '0') {
            // 如果大于1,并且保留位数是0
            this.conversionform.cClassHour = '' + Math.floor(resultClassHour)
          } else {
            let pow = 0;
            if (this.conversionform.cDigit > 0) {
              pow = Math.pow(10, (1 * this.conversionform.cDigit))
            } else {
              pow = 1
            }
            console.log(pow)
            this.conversionform.cClassHour = '' + Math.floor(resultClassHour * pow) / pow
          }

        }
        if (this.conversionform.cRetainDecimals == '20') {
          //小数四舍五入
          this.conversionform.cClassHour = '' + resultClassHour.toFixed(this.conversionform.cDigit)

        }
      }

    },


    getUrl(url) {
      if (url) {
        return require("@/assets/" + url + ".png");
      } else {
        return "";
      }
    },
    checkPassword(str) {
      //校验密码，密码长度8-20，必须包含大写字母、小写字母、数字、特殊符号中的三种
      let rC = {
        lW: "[a-z]",
        uW: "[A-Z]",
        nW: "[0-9]",
        sW: "[\\u0020-\\u002F\\u003A-\\u0040\\u005B-\\u0060\\u007B-\\u007E]",
      };
      function Reg(str, rStr) {
        let reg = new RegExp(rStr);
        if (reg.test(str)) return true;
        else return false;
      }
      if (str.length < 8) {
        return { flag: false, msg: "密码长度不得少于8位" };
      } else if (str.length > 20) {
        return { flag: false, msg: "密码长度不得超过20位" };
      } else {
        let tR = {
          l: Reg(str, rC.lW),
          u: Reg(str, rC.uW),
          n: Reg(str, rC.nW),
          s: Reg(str, rC.sW),
        };
        if (
          (tR.l && tR.u && tR.n) ||
          (tR.l && tR.u && tR.s) ||
          (tR.s && tR.u && tR.n) ||
          (tR.s && tR.l && tR.n)
        ) {
          return { flag: true, msg: "" };
        } else {
          return {
            flag: false,
            msg: "密码需由  小写字母、大写字母、数字、特殊符号  中的任意三种组成",
          };
        }
      }
    },
    doRouter(url) {
      const stu = this.$route.fullPath == url;
      if (url == null) {
        this.$alert("暂无权限或此功能暂未开放", "提示").catch(() => {
          return;
        });
      } else {
        if (!stu) {
          this.$router.push({
            path: url,
          });
        }
      }
    },
    // 获取菜单
    getMenuData() {
      const getMenu = this.$toTree(
        this.menu,
        "id",
        "parentId",
        "children",
        "orderId"
      ); // 获取菜单tree
      this.getMenu = getMenu;
    },
    getDetail(kpointId) {
      let that = this;
      that
        .$post("/biz/courseware/kpointView", {
          kpointId,
        })
        .then((result) => {
          if (result.status === "0") {
            let res = JSON.stringify(result);
            res = res.replace(/qualificationId/g, "teacherId");
            result = JSON.parse(res);
            this.ruleForm = {
              kpointName: result.data.kpointName,
              kpointLessonNum: result.data.kpointLessonNum,
              compOwnerName: result.data.compOwnerName,
              kpointVideoId: result.data.kpointVideoId,
              thumbnail: result.data.kpointLogo,
              trainTypeNamePath: result.data.trainTypeNamePath,
              postName: result.data.postName,
              industryNamePath: result.data.industryNamePath,
              occupationNamePath: result.data.occupationNamePath,
              trainLevelName: result.data.trainLevelName,
              duration: result.data.kpointDuration,
              kpointSource: result.data.kpointSource,
            };

            this.List.push(this.ruleForm.thumbnail);
            this.showVideo = true
          }
        });
    },
    // 换页面
    // changeBusinessType(command) {
    //   this.$post(
    //     "userService/queryMenuBussinessId",
    //     {
    //       bussinessId: command.id
    //     },
    //     true,
    //     "json"
    //   )
    //     .then(ret => {
    //       const retData = ret.data || [];
    //       // 缓存参数
    //       this.$store.dispatch("handleMenu", ret.user);
    //       const roleId = [];
    //       retData.forEach(item => {
    //         roleId.push(item.id);
    //       });
    //       this.$store.dispatch("handleUser", {
    //         ...this.userJson,
    //         roleId,
    //         businessId: command.id,
    //         businessName: command.businessName,
    //         operationType: command.operationType
    //       });
    //       this.menuActive = "1";
    //       this.menuOpeneds = ["1"];
    //       this.businessName = command.businessName;
    //       // 跳页
    //       if (ret.code === 0) {
    //         this.$router.push("/personalendusers");
    //         this.getMenuData();
    //       } else {
    //         this.$message.error(ret.msg || "请求失败，请稍后再试");
    //       }
    //     })
    //     .catch(() => {
    //       return;
    //     });
    // },
    // 刷新页面
    goRefash() {
      window.location.reload();
    },
    // 退出
    loginOut() {
      // this.$lWebsocket("closeWebsocket");
      clearInterval(this.getInfosSetInterval);
      this.$post("/logout", {}).catch((err) => {
        console.log(err);
      });
      this.$store.dispatch("handleDelAll");
      this.$router.push({
        path: "/web/login",
      });
    },
    // 导航
    changeCollapse() {
      this.isTrue = !this.isTrue;
      this.showtitle = !this.showtitle;
      this.setMenuStu("isTrue", this.isTrue);
    },
    menuSelect(index, arr) {
      return;
    },
    handleOpen() {
      return;
    },
    handleClose() {
      return;
    },
    setMenuStu(key, val) {
      const userJson = this.userJson || {};
      const menuStu = userJson.menuStu || {};
      menuStu[key] = val;
      userJson.menuStu = menuStu;
      this.$store.dispatch("handleUser", userJson);
    },
    // 图片放大
    picBigerClose() {
      this.$store.dispatch("handlePicBigUrl", "");
    },
    // 视频关闭 - 通用
    videoUrlClose() {
      this.$refs.player1.dd();
      this.$store.dispatch("handleVideoUrl", {});
      distorySwigger();
    },
    // 视频关闭 - 广州
    videoUrlClose1() {
      this.$store.dispatch("handleVideoUrl", {});
      distorySwigger();
    },
    // 数据字典
    setDictionary() {
      if (JSON.stringify(this.hasDictionary) !== "{}") {
        return;
      }
    },
    // 修改密码
    resetPassword() {
      const passform = this.$refs.passform;
      passform.validate((valid) => {
        if (valid) {
          this.$post("/sys/admin/modifyPassword", {
            newPassword: this.ruleForm.newPassword,
            oldPassword: this.ruleForm.oldPassword,
          }).then((res) => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.passworkchange = true;
              this.passwordShow = false;
              this.$refs.passform.resetFields();
              this.loginOut();
            }
          });
        }
      });
    },
    // 关闭修改密码 - 对话框
    passwordclose(done) {
      if (this.passworkchange) {
        this.$refs.passform.resetFields();
        this.passwordShow = false;
      }
    },
    // 获取消息列表数据
    getNewsInfo() {
      let pushData = {
        msgState: "10",
        pageNum: 1,
        pageSize: 5,
      };
      this.$post("/message/queryMessageList", pushData).then((res) => {
        this.newsInfo = res.data.list;
        this.newsInfoShow = true;
      });
    },
    // 跳转消息列表
    jumpNewsList() {
      this.newsInfoShow = false;
      this.$router.push("/web/newsList");
    },
    // 跳转消息列表 - 详情
    toNewsInfo(row) {
      this.newsInfoShow = false;
      //判断是否是当前路由
      if (this.$route.path == "/web/newsListInfo") {
        this.$router.push({
          path: "/web/redirect",
          query: {
            newsInfo: JSON.stringify(row),
          },
        });
      } else {
        this.$router.push({
          path: "/web/newsListInfo",
          query: {
            newsInfo: JSON.stringify(row),
          },
        });
      }
    },
    // 消息的小铃铛
    getInfos() {
      this.$post(
        "/message/getUnreadMsgCountByUserId",
        {
          userId: JSON.parse(sessionStorage.getItem("userJson")).userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.status == "0") {
          this.newsInfoNumber = res.data.unreadMsgCount;
        }
      });
    },
    // 获取数据
    getData() {
      this.$post("/message/unread/reminds")
        .then((ret) => {
          this.tableData = ret.data || [];
          if (ret.data.length) {
            this.onTimeReminder = true;
          } else {
            this.onTimeReminder = false;
          }
        })
        .catch((err) => {
          return;
        });
    },
    onTimeReminderclose() {
      this.onTimeReminder = false;
    },
    // 批量标记已读
    batchDeleteRead() {
      let arrData = [];
      for (let i = 0; i < this.tableData.length; i++) {
        arrData.push(Number(this.tableData[i].msgId));
      }
      let pushData = {
        ids: arrData,
        msgState: "30",
      };
      this.$post("/message/batchUpdateMagState", pushData).then((res) => {
        this.$message.success(res.message);
        this.getData();
      });
    },
    lookMore() {
      this.$router.push("/web/newsList");
      this.onTimeReminder = false;
    },
    //获取微信二维码
    getQRCode() {
      this.$post("/biz/admin/wechat/bindingQRCode", {}).then((res) => {
        if (res.status == 0) {
          this.QRCodeSrc = res.data;
        }
      });
    },
    checkHaveChannelQrCode() {
      this.$post("/biz/ct/channel/checkHaveChannelQrCode", {}).then((res) => {
        if (res.status == 0 && res.data) {
          this.channelId = res.data.channelId;
          this.showChannel = true;
        }
      });
    },
    getChannelQRCode() {
      this.$post("/biz/ct/channel/getQrCode", {
        channelId: this.channelId,
      }).then((res) => {
        if (res.status == 0) {
          this.ChannelQRCodeSrc = res.data.viewURL;
          this.ChannelQRCodeDownloadSrc = res.data.downloadURL;
          this.channelCodeShow = true;
        }
      });
    },
    // 下载二维码
    downloadChannelQRCode() {
      window.open(this.ChannelQRCodeDownloadSrc);
    },
    //获取绑定列表
    getBindQRCodeList() {
      this.$post("/biz/admin/wechat/list", {}).then((res) => {
        if (res.status == 0) {
          this.QRCodeBindList = res.data || [];
          // this.QRCodeSrc = res.data
        }
      });
    },
    //解除绑定
    handleUnbind(relationId) {
      this.$post("/biz/admin/wechat/remove", { relationId }).then((res) => {
        if (res.status == 0) {
          this.$message.success("解除成功");
          this.getBindQRCodeList();
        }
      });
    },
    // 顶部广告 - 跳转报名
    topAdvertisementInfo() {
      this.$router.push({
        path: "/web/accountManagement/advertisementInfo",
        query: {
          type: "add",
          refresh: true,
        },
      });
    },
    // 根据登录用户获取该机构的企业名称 - 用户为机构角色调用
    getMechanismEnterpriseName() {
      this.$post("/sys/company/queryCompType").then((res) => {
        if (res.status == 0) {
          if (res.data.compType == "20") {
            this.topAdvertisement = true;
          }
        }
      });
    },
  },
};
</script>
<style lang="less">
.bjInfo2 {
  background: red;
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 5px;
  vertical-align: middle;
  margin-top: -3px;
}

.menubox {
  .el-menu {
    border: none;
  }

  .el-menu-item {
    display: flex;
    align-items: center;

    &.is-active {
      background-color: #182943 !important;
      color: #fff;
    }
  }

  .el-submenu {
    &.is-opened .el-submenu__title {
      background-color: rgb(34, 48, 71) !important;
    }

    .el-submenu__title {
      padding: 0 !important;
      height: 40px;
      line-height: 40px;

      img {
        padding-left: 0.75rem;
      }

      span {
        padding-left: 0.75rem;
      }
    }

    .el-menu {
      .el-menu-item {
        padding-left: 55px !important;
        height: 40px;
        line-height: 40px;

        &.is-active {
          background-color: #182943 !important;
        }
      }
    }
  }

  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "" !important;
    }
  }
}

#app~.el-menu--vertical {
  .el-menu-item-group__title {
    padding: 0;
  }

  a {
    color: #fff;
  }

  .is-active {
    background-color: #1d2d47 !important;
  }
}
</style>
<style lang="less" scoped>
.menubox /deep/ .el-table__row:hover {
  cursor: pointer;
}

.menubox {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.menu {
  transition: all 0.3s;
  width: 3rem;
  height: 100%;
  background-color: #324767;
  box-shadow: 1px 0 8px 0 #b7b7b9;
  position: relative;
  z-index: 2;
  color: #fff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  overflow: initial;

  .seat {
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    overflow: hidden;
    align-items: center;

    .img-box {
      height: 1.5625rem;
      width: 1.5625rem;
    }
  }
}

.menu-cur {
  width: 13rem;
}

.menu .seat .header {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header-title {
  height: 6rem;
  background-color: #324767;
  box-shadow: 0 4px 4px 0 #293c59;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  .seat {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;

    img {
      width: 1.75rem;
    }
  }
}

h4 {
  flex: 1;
  font-size: 1.125rem;
  font-weight: 500;
  padding-left: 0.925rem;
  box-sizing: content-box;
}

.nav-title {
  padding-top: 0.25rem;
}

.nav-title .seat {
  font-size: 0;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

.nav-title .seat:hover {
  background-color: #1d2d47;
}

.nav {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  overflow: hidden;
}

.nav .nav-box {
  height: 100%;
  width: calc(100% + 17px);
  overflow-y: auto;
}

.nav .nav-box>ul {
  width: 100%;
  padding: 0;
  // padding-right: 17px;
  list-style: none;
}

.nav .nav-box>ul a {
  color: #fff;
  width: 100%;
  display: flex;
  overflow: hidden;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.nav .nav-box>ul>li> {
  padding: 0.5rem 0;
}

.nav .nav-box>ul>li>.seat {
  padding: 0.75rem 0;
  font-size: 1rem;
  border-bottom: 1px solid transparent;
}

.nav .nav-box>ul>li>.seat .img-box {
  padding-left: 0.75rem;
  box-sizing: content-box;
}

.nav .nav-box>ul>li>.seat span {
  padding-left: 0.75rem;
  box-sizing: content-box;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.Informationbox {
  flex: 1;
  padding-top: 3rem;
  height: 100%;
  overflow: hidden;

  section {
    height: 100%;
  }
}

.control {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
}

.control .header {
  height: 3rem;
  position: relative;
  box-shadow: 2px 2px 12px 0 #eeeeee;
  display: flex;
  overflow: hidden;
  align-items: center;
  overflow: initial;
  // justify-content: flex-end;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;

  .topAdvertisement {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    flex: 1;

    img {
      vertical-align: middle;
      width: 17px;
    }

    span {
      color: #f56c6c;
      font-weight: 500;
      cursor: pointer;
      border-radius: 30px;
      border: 1px solid #f56c6c;
      padding: 4px 8px;
      margin-left: 20px;
      transition: 0.3s;

      &:hover {
        color: #fff;
        background: #f56c6c;
      }
    }
  }
}

.headr-btn-title .img-box {
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
  }
}

.control .header .headerBtn {
  padding-right: 0.5rem;

  .seat {
    &>div {
      display: inline-block;
      vertical-align: middle;
      position: relative;

      &:first-child .headr-btn-title {
        border-left: none;
      }

      .headr-btn-title {
        min-width: 3.9375rem;
        text-align: center;
        border-left: 1px solid #eaeaeb;
        white-space: nowrap;
        padding: 0 0.84375rem;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          vertical-align: middle;
          white-space: nowrap;
          font-size: 0.875rem;
          line-height: normal;
          color: #8c8b8b;
        }

        .img-box {
          display: flex;
          align-items: center;
        }
      }

      &.headr-btn-out .headr-btn-title {
        border: 0;
      }

      &.headr-btn-title-border {
        border-right: 1px solid #eaeaeb;
        border-left: 1px solid #eaeaeb;
      }
    }
  }
}

.menuLabel {
  display: flex;
  flex-direction: column;
}

.videoMation {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;

  >span {
    width: 50%;
    padding: 5px 0;
    display: flex;
    align-items: center;

    >span {
      width: 120px;
    }

    div {
      margin-left: 10px;
      max-width: 27rem;
    }
  }
}

.headr-btn-title /deep/.el-badge {
  padding: 0 !important;
}

.menubox /deep/ .el-badge__content {
  top: -3px;
  right: 20px;
  height: 16px;
  line-height: 16.5px;
}

.Informationboxs {
  height: 100%;
}

.mongoliaStyle {
  background-image: url("../assets/loginInnerMongolia/login_top.png");
  color: white;

  span {
    color: white !important;
  }
}


/*课时换算*/
.conversion {
  /deep/.el-dialog {
    border-radius: 12px;
  }

  /deep/.el-dialog__header {
    padding-top: 30px !important;
    padding-bottom: 0 !important;
    background-color: transparent;

    .el-dialog__title {
      color: #333;
      font-weight: bold;
      font-size: 17px;
    }

    .el-icon-close:before {
      color: #333;
      border: 0;
    }

    .el-dialog__headerbtn {
      top: 30px;
    }
  }

  /deep/.el-dialog__body {
    padding: 14px 40px 0 !important;
  }

  /deep/.el-dialog__footer {
    padding-bottom: 30px !important;
  }

  /deep/.el-form-item:last-child {
    .el-form-item__label {
      color: #2878ff;
    }
  }

  /deep/.el-form-item__label,
  /deep/.el-form-item__content {
    line-height: 30px !important;

  }

  /deep/.el-form-item__content {
    font-size: 12px !important;
  }

  /deep/.el-dialog__footer {
    padding-top: 10px;
  }

  /deep/.dialog-footer {
    margin-top: 0;

    .bgc-bv {
      background-color: #2878ff !important;
      color: #fff !important;
      border-color: #2878ff !important;
    }
  }

  /deep/.el-form--label-left .el-form-item__label {
    font-weight: bold;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    padding: 0 4px !important;
  }

  /deep/.el-input.is-active .el-input__inner,
  /deep/.el-input__inner:focus {
    border-color: #409EFF;
  }

  /deep/.el-icon-arrow-up {
    color: #2878ff;
  }

  .resultBg {
    display: inline-block;
    width: 100%;
    background-color: rgba(40, 120, 255, 0.10);
    border-radius: 2px;
    padding: 0 8px;
    text-align: right;

    span {
      font-weight: bold;
      font-size: 14px;
      color: #2878ff;
      padding: 0 4px;
      cursor: pointer
    }
  }

  .conversionItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .conversionInner {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        margin-right: 0;
      }

      span {
        margin-left: 4px;
        min-width: 16px;
      }
    }

    &.type-1 {
      .conversionInner {
        width: calc(33% - 6px);

        /deep/.el-input-number {
          .el-input {
            width: 46px !important;
          }
        }
      }
    }

    &.type-2 {
      .conversionInner {
        width: 100%;

        /deep/.el-input-number--mini {
          width: 100% !important;
        }

        /deep/.el-input-number {
          .el-input {
            width: 100% !important;
          }
        }
      }
    }

    &.type-3 {
      .conversionInner {
        width: 50%;

        /deep/.el-select {
          &:nth-child(1) {
            .el-input {
              width: 100% !important;
            }
          }

          &:nth-child(2) {
            .el-input {
              width: 80px !important;
            }
          }

        }
      }
    }
  }
}
</style>